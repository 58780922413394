import { computed, ref, toRef, watch, type MaybeRefOrGetter } from "vue";
import { useI18n } from "vue-i18n";
import { ProjectRoutes } from "@/router/projectRoutes";
import { projectOverviewRoutes } from "@26lights/orcha";
import useHasAccess from "./useHasAccess";
import { useRoute } from "vue-router";
import { TabDefinition } from "@/helpers/typesDefinition";

export default function useProjectNav(id: MaybeRefOrGetter<string>) {
  const projectId = toRef(id);
  const { t } = useI18n({
    useScope: "global",
    inheritLocale: true,
  });

  const route = useRoute();

  const isCurrentRoute = (tab: TabDefinition) => {
    if (tab.route.name === route.name) return true;
    if (!tab.aliases) return false;
    for (const alias of tab.aliases) {
      if (alias === route.name) return true;
    }
    return false;
  };

  const currentTab = ref<TabDefinition>();

  const { hasAccessToFinancialData, hasAdminAccess } = useHasAccess(id);

  const tabs = computed<TabDefinition[]>(() => {
    const id = projectId.value;

    const overviewTab = {
      title: t("labels.overview"),
      route: {
        name: ProjectRoutes.PROJECT,
        params: { id },
      },
      iconName: "ri:home-line",
    };

    const reportingTab = {
      title: t("labels.reporting"),
      route: {
        name: ProjectRoutes.PROJECT_REPORTING,
        params: { id },
      },
      aliases: [
        ProjectRoutes.PROJECT_REPORTING_FINANCIAL_OVERVIEW,
        ProjectRoutes.PROJECT_REPORTING_FINANCIAL_THEMATIC,
        ProjectRoutes.PROJECT_REPORTING_FINANCIAL_INVOICES,
        ProjectRoutes.PROJECT_REPORTING_FINANCIAL_COMPANIES,
      ],
      iconName: "ic:baseline-bar-chart",
      children: [
        {
          title: t("labels.overview"),
          route: {
            name: ProjectRoutes.PROJECT_REPORTING_FINANCIAL_OVERVIEW,
            params: { id },
          },
          iconName: "ic:round-pie-chart",
        },
        {
          title: t("labels.byThematic"),
          route: {
            name: ProjectRoutes.PROJECT_REPORTING_FINANCIAL_THEMATIC,
            params: { id },
          },
          iconName: "ic:baseline-bar-chart",
        },
        {
          title: t("labels.byInvoices"),
          route: {
            name: ProjectRoutes.PROJECT_REPORTING_FINANCIAL_INVOICES,
            params: { id },
          },
          iconName: "mdi:currency-usd",
        },
        {
          title: t("labels.company"),
          route: {
            name: ProjectRoutes.PROJECT_REPORTING_FINANCIAL_COMPANIES,
            params: { id },
          },
          iconName: "ic:baseline-business",
        },
      ],
    };

    const budgetTab = {
      title: t("labels.projectBudget"),
      route: {
        name: ProjectRoutes.PROJECT_BUDGET_DETAILS,
        params: { id },
      },
      aliases: [
        ProjectRoutes.PROJECT_BUDGET_OVERVIEW,
        ProjectRoutes.PROJECT_ORDERS,
        ProjectRoutes.PROJECT_THEMATIC,
        ProjectRoutes.PROJECT_INVOICES,
      ],
      iconName: "ic:round-pie-chart",
      children: [
        {
          title: t("labels.overview"),
          route: {
            name: ProjectRoutes.PROJECT_BUDGET_OVERVIEW,
            params: { id: projectId.value },
          },
          iconName: "ic:round-pie-chart",
        },
        {
          title: t("labels.byThematic"),
          route: {
            name: ProjectRoutes.PROJECT_THEMATIC,
            params: { id: projectId.value },
          },
          iconName: "ic:baseline-bar-chart",
        },
        {
          title: t("labels.byOrders"),
          route: {
            name: ProjectRoutes.PROJECT_ORDERS,
            params: { id: projectId.value },
          },
          iconName: "mdi:cart",
        },
        {
          title: t("labels.byInvoices"),
          route: {
            name: ProjectRoutes.PROJECT_INVOICES,
            params: { id: projectId.value },
          },
          iconName: "mdi:currency-usd",
        },
      ],
    };

    const workTab = {
      title: t("labels.work"),
      route: {
        name: projectOverviewRoutes.project,
        params: { id },
      },
      aliases: [
        projectOverviewRoutes.feed,
        projectOverviewRoutes.milestone,
        projectOverviewRoutes.activity,
        ProjectRoutes.PROJECT_WORK_PLAN,
        ProjectRoutes.PROJECT_WORK_PLANNING,
      ],
      iconName: "ic:baseline-business",
      children: [
        {
          title: t("labels.overview"),
          route: {
            name: ProjectRoutes.PROJECT_WORK_PLAN,
            params: { id },
          },
          aliases: [projectOverviewRoutes.feed, "AoProject"],
          iconName: "ic:round-pie-chart",
        },
        {
          title: t("labels.planning"),
          route: {
            name: ProjectRoutes.PROJECT_WORK_PLANNING,
            params: { id },
          },
          iconName: "mdi:calendar-month",
        },
      ],
    };

    const settingTabs = {
      title: t("labels.settings"),
      route: {
        name: ProjectRoutes.PROJECT_SETTINGS,
        params: { id },
      },
    };

    if (id && hasAccessToFinancialData.value) {
      return [
        overviewTab,
        budgetTab,
        workTab,
        reportingTab,
        ...(hasAdminAccess.value ? [settingTabs] : []),
      ];
    }
    return [workTab];
  });

  watch(tabs, (t) => {
    currentTab.value = t?.find((tab) => isCurrentRoute(tab));
  });

  const subTabs = computed(() => {
    return currentTab.value?.children;
  });

  return {
    tabs,
    currentTab,
    subTabs,
  };
}
