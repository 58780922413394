import gql from "graphql-tag";
import { deltaFragment } from "./fragments";

export const GET_OPENED_DELTAS = gql`
  query getOpenedDeltas($offset: Int!, $limit: Int) {
    openedDeltas(offset: $offset, limit: $limit) {
      hasMore
      items {
        id
        startDate
        endDate
        timesheetTotal
        deltaTotal
        ...DeltaFragment
        monthsSpan
        project {
          id
          name
        }
      }
    }
  }
  ${deltaFragment}
`;

export const GET_NON_ORDERED_DELTAS = gql`
  query getNonOrderedDeltas($offset: Int!, $limit: Int) {
    nonOrderedDeltas(offset: $offset, limit: $limit) {
      hasMore
      items {
        id
        startDate
        endDate
        timesheetTotal
        monthsSpan
        project {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROJECT_COMPANIES = gql`
  query projectCompanies($id: ID!) {
    project(id: $id) {
      id
      client {
        companies {
          id
          name
        }
      }
    }
  }
`;
