import gql from "graphql-tag";

export const deltaFragment = gql`
  fragment DeltaFragment on Delta {
    id
    startDate
    endDate
    ordersTotal
    timesheetTotal
    deltaTotal
    deltaStatus
    showDetailsOnInvoice
    invoice {
      id
      dueDate
    }
    deltaCompany: company {
      id
      name
    }
  }
`;
