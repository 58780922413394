
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/delta/en-UK.json";

import PaginatedEntitiesList from "@/components/list/PaginatedEntitiesList.vue";
import EditDelta from "@/components/CRUXEntity/EditDelta.vue";

import { GET_OPENED_DELTAS } from "@/graphql/delta/queries";

import { Delta } from "@/helpers/delta/typesDefinition";
import dayjs from "dayjs";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const selectAll = ref(false);
    const checkBoxes = ref();
    const deltas = ref();
    const clickedDelta = ref();

    const loading = ref(false);

    return {
      t,
      checkBoxes,
      deltas,
      loading,
      selectAll,
      clickedDelta,
    };
  },
  data() {
    return {
      enUK,
      GET_OPENED_DELTAS,
      isDeltaModalVisible: false,
    };
  },
  emits: [],
  provide: {
    columnsOrder: {
      project: null,
      deltaCompany: null,
      ordersTotal: null,
      timesheetTotal: null,
      deltaTotal: null,
      startDate: null,
      deltaStatus: null,
    },
  },
  methods: {
    showDeltaModal(delta: Delta): void {
      this.clickedDelta = delta;
      this.isDeltaModalVisible = true;
    },
    closeDeltaModal(): void {
      this.isDeltaModalVisible = false;
    },
    formatDate(date: string, _name: string, delta: Delta): string {
      const baseFormat = dayjs(date).format("MMMM YY");
      if (delta.monthsSpan > 1)
        return `${baseFormat} (+${delta.monthsSpan - 1})`;
      return baseFormat;
    },
  },
  components: {
    PaginatedEntitiesList,
    EditDelta,
  },
});
