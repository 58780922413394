import { RouteRecordRaw } from "vue-router";

import Projects from "@/views/project/Projects.vue";
import ProjectDetails from "@/views/project/ProjectDetails.vue";
import InvoicesMenu from "@/views/project/InvoicesMenu.vue";
import RecurringOrders from "@/views/project/RecurringOrders.vue";
import ProjectCompaniesSummary from "@/views/project/ProjectCompaniesSummary.vue";
import ProjectFinancialDashboard from "@/views/project/ProjectFinancialDashboard.vue";
import ProjectsList from "@/views/project/ProjectsList.vue";
import ProjectExternalDashboard from "@/views/project/ProjectExternalDashboard.vue";
import { getProjectOverviewRoutes } from "@26lights/orcha";
import ProjectOverview from "@/views/project/ProjectOverview.vue";
import { getUser, isAdmin, isProjectManager } from "@/helpers/router/queries";
import checkHasAccess from "@/helpers/permissions/checkHasAccess";
import ProjectThemesAndBudget from "@/views/project/ProjectThemesAndBudget.vue";
import ProjectSettings from "@/views/project/ProjectSettings.vue";
import ProjectPlanning from "@/views/project/ProjectPlanning.vue";
import ProjectDashboard from "@/views/project/ProjectDashboard.vue";
import ProjectBudget from "@/views/project/ProjectBudget.vue";
import ProjectReportingBase from "@/views/project/ProjectReportingBase.vue";
import ProjectOrders from "@/views/project/ProjectOrders.vue";
import ProjectInvoices from "@/views/project/ProjectInvoices.vue";
import ProjectReportingInvoices from "@/views/project/ProjectReportingInvoices.vue";
import ProjectReportingThemactic from "@/views/project/ProjectReportingThematic.vue";
import ProjectWork from "@/views/project/ProjectWork.vue";

export const ProjectRoutes = {
  PROJECTS: "PROJECTS",
  PROJECTS_ACTIVE: "PROJECTS_ACTIVE",
  PROJECTS_INACTIVE: "PROJECTS_INACTIVE",
  PROJECT: "PROJECT",
  PROJECT_ROOT: "PROJECT_ROOT",
  PROJECT_REPORTING: "PROJECT_REPORTING",
  PROJECT_THEMES_BUDGET: "PROJECT_THEMES_BUDGET",
  PROJECT_ORDER_DETAILS: "PROJECT_ORDER_DETAILS",
  PROJECT_DETAILS: "PROJECT_DETAILS",
  PROJECT_RECURRING_ORDERS: "PROJECT_RECURRING_ORDERS",
  PROJECT_RECURRING_ORDER_DETAILS: "PROJECT_RECURRING_ORDER_DETAILS",
  PROJECT_BUDGET_OVERVIEW: "PROJECT_BUDGET_OVERVIEW",
  PROJECT_BUDGET_DETAILS: "PROJECT_BUDGET_DETAILS",
  PROJECT_SETTINGS: "PROJECT_SETTINGS",
  PROJECT_COMPANIES_SUMMARY: "PROJECT_COMPANIES_SUMMARY",
  PROJECT_ORDERS: "PROJECT_ORDERS",
  PROJECT_THEMATIC: "PROJECT_THEMATIC",
  PROJECT_INVOICES: "PROJECT_INVOICES",
  PROJECT_REPORTING_FINANCIAL_OVERVIEW: "PROJECT_REPORTING_FINANCIAL_OVERVIEW",
  PROJECT_REPORTING_FINANCIAL_THEMATIC: "PROJECT_REPORTING_FINANCIAL_THEMATIC",
  PROJECT_REPORTING_FINANCIAL_INVOICES: "PROJECT_REPORTING_FINANCIAL_INVOICES",
  PROJECT_REPORTING_FINANCIAL_COMPANIES:
    "PROJECT_REPORTING_FINANCIAL_COMPANIES",
  PROJECT_PLANNING: "PROJECT_PLANNING",
  PROJECT_WORK: "PROJECT_WORK",
  PROJECT_WORK_PLAN: "PROJECT_WORK_PLAN",
  PROJECT_WORK_PLANNING: "PROJECT_WORK_PLANNING",
} as const;

export type ProjectRoutesKeys = keyof typeof ProjectRoutes;
export type ProjectRoutesValues = (typeof ProjectRoutes)[ProjectRoutesKeys];

export const projectRoutes: Array<RouteRecordRaw> = [
  {
    path: "/projects",
    name: ProjectRoutes.PROJECTS,
    component: Projects,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: ProjectRoutes.PROJECTS_ACTIVE,
        component: ProjectsList,
        props: { active: true },
      },
      {
        path: "inactive",
        name: ProjectRoutes.PROJECTS_INACTIVE,
        component: ProjectsList,
        props: { active: false },
      },
    ],
  },
  {
    path: "/project/:id",
    name: ProjectRoutes.PROJECT_ROOT,
    component: ProjectDetails,
    meta: { requiresAuth: true },
    props: true,
    beforeEnter: async (to) => {
      if (to.path === `/project/${to.params.id}`) {
        const user = await getUser();
        if (
          isAdmin(user.accessRights) ||
          isProjectManager(user.accessRights) ||
          (await checkHasAccess(undefined, to.params.id as string, "client"))
        ) {
          return { name: ProjectRoutes.PROJECT, params: to.params };
        }
        return { name: ProjectRoutes.PROJECT_WORK, params: to.params };
      }
    },
    children: [
      {
        path: "overview",
        name: ProjectRoutes.PROJECT,
        component: ProjectDashboard,
        props: true,
      },
      {
        path: "reporting",
        name: ProjectRoutes.PROJECT_REPORTING,
        component: ProjectReportingBase,
        redirect: {
          name: ProjectRoutes.PROJECT_REPORTING_FINANCIAL_OVERVIEW,
        },
        props: true,
        children: [
          {
            path: "financial/overview",
            name: ProjectRoutes.PROJECT_REPORTING_FINANCIAL_OVERVIEW,
            component: ProjectExternalDashboard,
            props: true,
          },
          {
            path: "financial/companies",
            name: ProjectRoutes.PROJECT_REPORTING_FINANCIAL_COMPANIES,
            component: ProjectCompaniesSummary,
            props: true,
          },
          {
            path: "financial/invoices",
            name: ProjectRoutes.PROJECT_REPORTING_FINANCIAL_INVOICES,
            component: ProjectReportingInvoices,
            props: true,
          },
          {
            path: "financial/themes",
            name: ProjectRoutes.PROJECT_REPORTING_FINANCIAL_THEMATIC,
            component: ProjectReportingThemactic,
            props: true,
          },
        ],
      },
      {
        path: "themes",
        name: ProjectRoutes.PROJECT_BUDGET_DETAILS,
        component: ProjectThemesAndBudget,
        props: true,
      },
      {
        path: "order/:orderId",
        name: ProjectRoutes.PROJECT_ORDER_DETAILS,
        component: InvoicesMenu,
        props: true,
      },
      {
        path: "details",
        name: ProjectRoutes.PROJECT_DETAILS,
        component: InvoicesMenu,
        props: true,
      },
      {
        path: "recurring-orders",
        name: ProjectRoutes.PROJECT_RECURRING_ORDERS,
        component: RecurringOrders,
        props: true,
      },
      {
        path: "recurring-order/:orderId",
        name: ProjectRoutes.PROJECT_RECURRING_ORDER_DETAILS,
        component: RecurringOrders,
        props: true,
      },
      {
        path: "budget",
        name: ProjectRoutes.PROJECT_BUDGET_DETAILS,
        redirect: { name: ProjectRoutes.PROJECT_BUDGET_OVERVIEW },
        component: ProjectBudget,
        children: [
          {
            path: "overview",
            name: ProjectRoutes.PROJECT_BUDGET_OVERVIEW,
            component: ProjectFinancialDashboard,
            props: true,
          },
          {
            path: "orders/:orderId?",
            name: ProjectRoutes.PROJECT_ORDERS,
            component: ProjectOrders,
            props: true,
          },
          {
            path: "themes",
            name: ProjectRoutes.PROJECT_THEMATIC,
            component: ProjectThemesAndBudget,
            props: true,
          },
          {
            path: "invoices",
            name: ProjectRoutes.PROJECT_INVOICES,
            component: ProjectInvoices,
            props: true,
          },
        ],
        props: true,
      },
      {
        path: "settings",
        name: ProjectRoutes.PROJECT_SETTINGS,
        component: ProjectSettings,
        props: true,
      },
      {
        path: "work",
        name: ProjectRoutes.PROJECT_WORK,
        component: ProjectWork,
        props: true,
        redirect: { name: ProjectRoutes.PROJECT_WORK_PLAN },
        children: [
          ...getProjectOverviewRoutes("plan", {
            name: ProjectRoutes.PROJECT_WORK_PLAN,
            component: ProjectOverview,
          }),
          {
            path: "planning",
            name: ProjectRoutes.PROJECT_WORK_PLANNING,
            component: ProjectPlanning,
            props: true,
          },
        ],
      },
    ],
  },
];
