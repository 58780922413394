import { DocumentNode } from "graphql";
import { Client } from "./client/typesDefinition";
import { User } from "./user/typesDefinition";
import { Project } from "@/helpers/project/typesDefinition";
import { FeedFilters } from "@26lights/orcha";
import { type RouteLocationNamedRaw } from "vue-router";

export interface Entity {
  __typename: string;
  id: string;
}

export interface Response<T> extends DataResponse<T>, ErrorResponse {
  status: string;
}

interface DataResponse<T> {
  data: T;
}
interface ErrorResponse {
  error: string;
}

export interface AccessRight {
  __typename: string;
  user: User;
  role: string;
}

export interface ProjectAccessRight extends AccessRight {
  project: Project;
}

export function isProjectAccessRight(
  accessRight: AccessRight,
): accessRight is ProjectAccessRight {
  return accessRight.__typename === "ProjectAccessRight";
}

export interface ClientAccessRight extends AccessRight {
  client: Client;
}

export interface PaginatedResult<T> {
  hasMore: boolean;
  items: T[];
}

export interface PaginatedResponse<T> {
  [key: string]: PaginatedResult<T>;
}

export type Comparator<T, U> = (e1: T, e2: U) => number;

export interface PaginatedCacheInfoHolder<T, U> {
  query: DocumentNode;
  comparator: Comparator<T, T>;
  cachedPaginatedResult: (cachedResponse: U | null) => PaginatedResult<T>;
  cachedResponse: (paginatedResult: PaginatedResult<T>) => U;
}

export interface EntityPaginatedPropCacheInfoHolder<T, U, V extends Entity> {
  query: DocumentNode;
  comparator: Comparator<T, T>;
  cachedPaginatedResult: (cachedResponse: U | null) => PaginatedResult<T>;
  cachedEntity: (cachedResponse: U | null, id: string) => V;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cachedResponse: (paginatedResult: PaginatedResult<T>, entity: any) => U;
}

export interface CacheInfoHolder<T, U> {
  query: DocumentNode;
  comparator: Comparator<T, T>;
  cachedEntitiesToUpdate: (cachedResponse: U) => T[];
  cachedResponse: (cachedResponse: U, updatedEntities: T[]) => U;
}

export type FeedFiltersSearch = FeedFilters & {
  searchTerm: "";
  includesInactive: boolean;
  onlyImportant: boolean;
};

export type DonutSegment = {
  id: string;
  name: string;
  color: string;
  percentage: number;
  offset: number;
  subtotal?: number;
};

export type TabDefinition = {
  title: string;
  route: Pick<RouteLocationNamedRaw, "name" | "params">;
  aliases?: string[];
  iconName?: string;
  children?: TabDefinition[];
};
