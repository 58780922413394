
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/delta/en-UK.json";

import FormItem from "@/components/form/FormItem.vue";
import Modal from "@/components/Modal.vue";
import BasicButton from "@/components/BasicButton.vue";

import { GET_PROJECT_COMPANIES } from "@/graphql/delta/queries";
import { CREATE_INVOICE_FOR_DELTA } from "@/graphql/delta/mutations";

import { useToast } from "vue-toastification";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import dayjs from "dayjs";
import { DatePickerMarker } from "@vuepic/vue-datepicker";

type MessageSchema = typeof enUK;

export default defineComponent({
  props: {
    delta: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();

    const {
      result,
      loading: loadingCompanies,
      onError: onErrorCompanies,
    } = useQuery(GET_PROJECT_COMPANIES, () => ({
      id: props.delta.project.id,
    }));

    const companies = useResult(
      result,
      null,
      (result) => result?.project?.client?.companies,
    );

    const showDetailsOnInvoice = ref(props.delta.showDetailsOnInvoice);
    const companyId = ref(props.delta.deltaCompany?.id || "");
    const dueDate = ref(props.delta.invoice?.dueDate);

    const variables = reactive({ companyId, showDetailsOnInvoice, dueDate });
    const v$ = useVuelidate(
      { companyId: required, dueDate: required, showDetailsOnInvoice: {} },
      variables,
      {
        $autoDirty: true,
      },
    );

    onErrorCompanies((error) => {
      logErrorMessages(error);
      toast.error(t("errors.getDeltaCompanies"));
    });

    const {
      mutate: createInvoice,
      onDone,
      onError,
      loading,
    } = useMutation(CREATE_INVOICE_FOR_DELTA, () => ({
      variables: {
        id: props.delta.id,
        companyId: companyId.value,
        dueDate: dueDate.value,
        showDetailsOnInvoice: showDetailsOnInvoice.value,
      },
    }));

    onDone(async (result) => {
      if (result.data?.createInvoiceForDelta?.status === "ok") {
        toast.success(t("success.createInvoice"));
        context.emit("close");
      } else {
        const errorResponse = result.data?.createInvoiceForDelta?.error;
        console.log(errorResponse);
        toast.error(t("errors.createInvoice", { message: errorResponse }));
      }
    });

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.createInvoice", { message: t("errors.internal") }));
    });

    const submitForm = async () => {
      const isFormCorrect = await v$.value.$validate();
      if (isFormCorrect) {
        return createInvoice();
      }
    };

    const dateMarkers: DatePickerMarker[] = [
      {
        date: dayjs().add(10, "days").toDate(),
        type: "line",
        tooltip: [{ text: t("labels.defaultDueDate"), color: "blue" }],
      },
    ];

    return {
      t,
      createInvoice,
      loading,
      companyId,
      showDetailsOnInvoice,
      dueDate,
      loadingCompanies,
      companies,
      v$,
      submitForm,
      dateMarkers,
    };
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    Modal,
    FormItem,
    BasicButton,
  },
});
