import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7fd2ea8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wrapper"
}
const _hoisted_2 = { class: "header-boxes" }
const _hoisted_3 = {
  key: 0,
  class: "kpi"
}
const _hoisted_4 = { class: "text-with-badge" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "gross-net-wrapper" }
const _hoisted_7 = { class: "value" }
const _hoisted_8 = { class: "gross-net" }
const _hoisted_9 = { class: "value subtext" }
const _hoisted_10 = { class: "gross-net" }
const _hoisted_11 = {
  key: 1,
  class: "kpi"
}
const _hoisted_12 = { class: "text-with-badge" }
const _hoisted_13 = { class: "label" }
const _hoisted_14 = { class: "value" }
const _hoisted_15 = {
  key: 2,
  class: "kpi"
}
const _hoisted_16 = { class: "text-with-badge" }
const _hoisted_17 = { class: "label" }
const _hoisted_18 = { class: "kpi" }
const _hoisted_19 = { class: "budget-progress__label" }
const _hoisted_20 = { class: "label" }
const _hoisted_21 = { class: "value" }
const _hoisted_22 = { class: "kpi" }
const _hoisted_23 = { class: "label" }
const _hoisted_24 = { class: "value" }
const _hoisted_25 = { class: "cascade budget" }
const _hoisted_26 = { class: "label" }
const _hoisted_27 = { class: "value" }
const _hoisted_28 = { class: "cascade total-orders" }
const _hoisted_29 = { class: "label" }
const _hoisted_30 = { class: "value" }
const _hoisted_31 = { class: "cascade approved-orders" }
const _hoisted_32 = { class: "label" }
const _hoisted_33 = { class: "value" }
const _hoisted_34 = { class: "label" }
const _hoisted_35 = { class: "value gross-net-wrapper" }
const _hoisted_36 = { class: "value" }
const _hoisted_37 = {
  key: 0,
  class: "gross-net"
}
const _hoisted_38 = {
  key: 0,
  class: "value subtext"
}
const _hoisted_39 = { class: "gross-net" }
const _hoisted_40 = {
  key: 0,
  class: "cascade paid last-cascade"
}
const _hoisted_41 = { class: "label" }
const _hoisted_42 = { class: "value gross-net-wrapper" }
const _hoisted_43 = { class: "value" }
const _hoisted_44 = { class: "gross-net" }
const _hoisted_45 = { class: "value subtext" }
const _hoisted_46 = { class: "gross-net" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_ctx.project)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.project.internal)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.project.totalBilled > _ctx.project.totalPaid)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, [
                        _createVNode(_component_Badge, { colorName: "error" }),
                        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t("labels.budgetDashboard.toPay")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("p", _hoisted_7, [
                          _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.project.totalBilled - _ctx.project.totalPaid)) + " ", 1),
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t("labels.budgetDashboard.gross")), 1)
                        ]),
                        _createElementVNode("p", _hoisted_9, [
                          _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.project.totalBilledNet - _ctx.project.totalPaidNet)) + " ", 1),
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t("labels.budgetDashboard.net")), 1)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.project.totalBilled < _ctx.project.totalPaid)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, [
                        _createVNode(_component_Badge, { colorName: "light" }),
                        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t("labels.budgetDashboard.currentBalance")), 1)
                      ]),
                      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.formatPrice(_ctx.project.totalPaid - _ctx.project.totalBilledNet)), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.project.totalBilled === _ctx.project.totalPaid)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("span", _hoisted_16, [
                        _createVNode(_component_Badge, { colorName: "success" }),
                        _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.t("labels.budgetDashboard.balanced")), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.t("labels.budgetDashboard.budgetProgress")), 1)
            ]),
            _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.formatPrice(_ctx.project.performedAmount)), 1),
            _createVNode(_component_ProgressBar, {
              class: "budget-progress__bar",
              percentages: _ctx.project.performedAmount / _ctx.budget
            }, null, 8, ["percentages"])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.t("labels.budgetDashboard.remainingBudget")), 1),
            _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.budget ? _ctx.formatPrice(_ctx.budget - _ctx.project.performedAmount) : "-"), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.t("labels.budgetDashboard.budget")), 1),
          _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.formatPrice(_ctx.budget)), 1),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.t("labels.budgetDashboard.totalOrders")), 1),
            _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.formatPrice(_ctx.project.totalOrderedAmount)), 1),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.t("labels.budgetDashboard.approvedOrders")), 1),
              _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.formatPrice(_ctx.project.totalApprovedOrderedAmount)), 1),
              _createElementVNode("div", {
                class: _normalizeClass(["cascade invoiced", _ctx.project.internal ? 'last-cascade' : ''])
              }, [
                _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.t("labels.budgetDashboard.invoiced")), 1),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("p", _hoisted_36, [
                    _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.project.totalBilledNet)) + " ", 1),
                    (!_ctx.project.internal)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_ctx.t("labels.budgetDashboard.net")), 1))
                      : _createCommentVNode("", true)
                  ]),
                  (!_ctx.project.internal)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_38, [
                        _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.project.totalBilled)) + " ", 1),
                        _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.t("labels.budgetDashboard.gross")), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (!_ctx.project.internal)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                      _createElementVNode("p", _hoisted_41, _toDisplayString(_ctx.t("labels.budgetDashboard.paid")), 1),
                      _createElementVNode("div", _hoisted_42, [
                        _createElementVNode("p", _hoisted_43, [
                          _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.project.totalPaidNet)) + " ", 1),
                          _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.t("labels.budgetDashboard.net")), 1)
                        ]),
                        _createElementVNode("p", _hoisted_45, [
                          _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.project.totalPaid)) + " ", 1),
                          _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.t("labels.budgetDashboard.gross")), 1)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}