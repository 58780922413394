import { ApolloClient, FieldPolicy, InMemoryCache } from "@apollo/client/core";

import { Reference } from "@apollo/client/utilities";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { PaginatedResult } from "./helpers/typesDefinition";
import config from "./config";

type KeyArgs = FieldPolicy["keyArgs"];

function customOffsetLimitPagination<T = Reference>(
  keyArgs: KeyArgs = false,
): FieldPolicy<PaginatedResult<T>> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const mergedItems = existing?.items ? existing.items.slice(0) : [];
      if (args) {
        const { offset } = args;
        if (!offset) return incoming; // No offset defined means manual cache update
        for (let i = 0; i < incoming.items.length; ++i) {
          mergedItems[offset + i] = incoming.items[i];
        }
      } else {
        mergedItems.push(...incoming.items);
      }
      return { ...incoming, items: mergedItems };
    },
  };
}
const cache = new InMemoryCache({
  possibleTypes: {
    BasePhase: ["Phase", "TemplatePhase"],
    BaseMilestone: ["Milestone", "TemplateMilestone"],
    BaseActivity: ["Activity", "TemplateActivity"],
    ClientAction: [
      "InvoicePaymentAction",
      "OrderApprobationAction",
      "BudgetApprobationAction",
    ],
  },
  addTypename: true,
  typePolicies: {
    Query: {
      fields: {
        clients: customOffsetLimitPagination(),
        projects: customOffsetLimitPagination(["active"]),
        openedDeltas: customOffsetLimitPagination(),
        nonOrderedDeltas: customOffsetLimitPagination(),
        independentInvoices: customOffsetLimitPagination(),
        invoices: customOffsetLimitPagination(),
        employeeProjects: customOffsetLimitPagination(),
        userInvoices: customOffsetLimitPagination(),
        clientActions: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
        ordersDeltasAndInvoices: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Project: {
      fields: {
        orders: customOffsetLimitPagination(),
        clientActions: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
        invoicesMenu: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
        recurringOrders: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Client: {
      fields: {
        accessRights: customOffsetLimitPagination(),
      },
    },
  },
});

const backendBaseUri = config.backendUri;

const link = new BatchHttpLink({
  uri: `${backendBaseUri}/graphql`,
  credentials: "include",
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link,
  cache,
});
